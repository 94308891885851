import md5 from "md5";
const keyMpadding = {
  development: "p*Tp*NI#TS",
  testing: "v*Tp#NI*TS",
  production: "v*Tp#NI*TS",
};
export const md5SignEncode = (str, env) => {
  return md5(
    `${str}&key=${
      keyMpadding[env || process.env.NODE_ENV] || keyMpadding.development
    }`
  );
};