import axios from "axios";
import { Message } from 'element-ui'
import { md5SignEncode } from "@/utils/index";
const service = axios.create({
  // baseURL:
  //   "/api"
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 10000 // request timeout
})
service.interceptors.request.use(
  config => {
    // config.method = 'POST'
    config.headers['token'] = sessionStorage.getItem('token')
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    // config.headers['AppCode'] = localStorage.getItem('appCode') || ''
    config.headers['Accept-Language'] = 'zh-CN,zh;q=0.9'
    config.headers['Authorization'] = sessionStorage.getItem('Authorization')
      let acceptParams,es;
  es = sessionStorage.getItem('es');
  if(config.method == 'post'){ //post请求
    acceptParams = config.data ? config.data : {};
    config.data = {
      ...acceptParams,
      platform:5, //平台(目前固定为5:web)
      extend_source:es || 130,//渠道ID
    }
  }else if(config.method == 'get'){ //get请求
    acceptParams = config.params ? config.params : {};
    config.params = {
      ...acceptParams,
      platform:5, //平台(目前固定为5-web)
      extend_source:es || 130,//渠道ID
    }
  }

  if(config.url === '/api/v2/verify_codes'){ //此接口签名不一样
    acceptParams = config.data;
    config.data = {
      ...acceptParams,
      sign:md5SignEncode(`email=${acceptParams.email}&extend_source=${acceptParams.extend_source}&platform=${acceptParams.platform}&timestamp=${acceptParams.timestamp}`)
    }
  }

  //公告需要签名
  if(config.url === '/api/v2/get_notice'){
    acceptParams = config.data;
    config.params = {
      ...acceptParams,
      // sign:md5SignEncode(`app_name=${'bluerabbit'}&extend_source=${acceptParams.extend_source}&notice_type=${acceptParams.notice_type}&platform=${acceptParams.platform}&timestamp=${acceptParams.timestamp}&user_id=${acceptParams.user_id}`)
      sign:md5SignEncode(`app_name=${'bluerabbit'}&extend_source=${acceptParams.extend_source}&notice_type=${acceptParams.notice_type}&platform=${acceptParams.platform}&timestamp=${acceptParams.timestamp}&user_id=${acceptParams.user_id}`)
    }
  }
    // console.log(config)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.config.url === '/api/v2/users/login') {
      sessionStorage.setItem('Authorization', response.headers.authorization)
    }
    const res = response.data
    if (response.headers['content-type'] === 'application/json') {
      if (res.size) { // 针对导出excel失败之后的处理等 2022年3月29日
        // 将blob转为json
        const reader = new FileReader()
        let parseObj = null
        reader.readAsText(response.data, 'utf-8')
        reader.onload = function () {
          parseObj = JSON.parse(reader.result)
          Message.error(parseObj.message || 'Error')
        }
        return Promise.reject(new Error(res.message || 'Error'))
      } else {
        if (res.status_code !== 200 && res.status_code!==200050&& res.status_code!==200051 &&res.status_code!==200001) {
          Message.error(res.message || 'Error')
          return Promise.reject(new Error(res.message || 'Error'))
        } else {
          return res
        }
      }
    } else {
      return response
    }
  },
  error => {
    // Message({
    //   message: error.response.error,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)


export default service